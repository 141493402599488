<template lang="pug">
  .form-el(
    :class="classObject"
  )
    .form-el__title(v-if="title") {{title}}
    .form-el__body
      el-input(
      v-if="type === 'input'"
      v-model="localValue"
      :placeholder="placeholder"
      :size="inputSize"
      :disabled="disabled"
      ref="inputWrapper"
      )
      el-input(
      v-if="type === 'password'"
      v-model="localValue"
      :placeholder="placeholder"
      :size="inputSize"
      :disabled="disabled"
      ref="inputWrapper"
      readonly onfocus="this.removeAttribute('readonly')"
      show-password
      )
      el-select(
        v-if="type === 'select'"
        v-model="localValue"
        :placeholder="placeholder"
        :size="inputSize"
        :disabled="disabled"
        :filterable="filterable"
        :class="elClass"
        )
        el-option(
          v-for="(item, $index) in options"
          :key="$index"
          :value="item.value"
          :label="item.label"
          )

      //- autocomplete с добавлением новых атрибутов
      div(v-if="type === 'autocomplete'").custom-autocomplete
        button.btn(
          v-if="editor"
          v-show="showAddBtn"
          @click.prevent="addOption"
        )
          svg(width="16" height="16")
            use(xlink:href="@/assets/images/sprite/sprite.svg#icon-plus")
        el-autocomplete(
          v-model="localValue"
          :fetch-suggestions="querySearch"
          :value-key="valueKey"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="elClass"
          @select="$emit('select')"
        )
      //--------------------------------------------

      el-date-picker(
      v-if="type === 'calendar'"
      v-model="localValue"
      :type="calendarType"
      :placeholder="placeholder"
      :picker-options="datePickerOptions"
      :size="inputSize"
      :format="dateFormat"
      value-format="yyyy-MM-dd"
      :disabled="disabled"
      :clearable="clearable"
      @focus="$emit('on-picker-switched', true)"
      @blur="$emit('on-picker-switched', false)"
      )
      el-date-picker(
      v-if="type === 'range'"
      v-model="localValue"
      :type="rangeType"
      range-separator=":"
      start-placeholder="Дата начала"
      end-placeholder="Дата окончания"
      :picker-options="datePickerOptions"
      :size="inputSize"
      :clearable="clearable"
      format="dd.MM.yyyy"
      value-format="yyyy-MM-dd"
      @focus="$emit('on-picker-switched', true)"
      @blur="$emit('on-picker-switched', false)"
      )
      el-date-picker(
      v-if="type === 'datetime'"
      v-model="localValue"
      :type="'datetime'"
      :placeholder="placeholder"
      :picker-options="dateTimePickerOptions"
      :format="'dd.MM.yyyy HH:mm'"
      @focus="$emit('on-picker-switched', true)"
      @blur="$emit('on-picker-switched', false)"
      )
      el-switch(
      v-if="type === 'switch'"
      v-model="localValue"
      :active-text="activeText"
      :inactive-text="inactiveText"
      )
      el-time-picker(
      v-if="type === 'time'"
      v-model="localValue"
      :value-format="'HH:mm'"
      :placeholder="placeholder"
      :picker-options="timePickerOptions"
      :disabled="disabled"
      )
      el-checkbox(
      v-if="type === 'checkbox'"
      v-model="localValue"
      :size="inputSize"
      ) {{text}}
      a-upload(
        v-if="type === 'upload'"
        :upload-url="uploadUrl"
      )
</template>
<script>
//todo: выпилить лишние (неиспользуемые) элементы

import {
  Select,
  Option,
  Input,
  DatePicker,
  Switch,
  TimePicker,
  Checkbox,
} from "element-ui";
// import AUpload from "../a-upload/a-upload";

export default {
  name: "a-form-el",
  components: {
    // AUpload,
    ElSelect: Select,
    ElOption: Option,
    ElInput: Input,
    ElDatePicker: DatePicker,
    ElSwitch: Switch,
    ElTimePicker: TimePicker,
    ElCheckbox: Checkbox,
  },
  props: {
    fieldName: {
      type: String,
      default: "",
      required: true,
    },
    type: {
      type: String,
      default: "input",
      required: true,
    },
    value: {
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    inputSize: {
      type: String,
      default: "large",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    inputMask: {
      type: Object,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    dev: {
      //подсвечивает form-el красным цветом, необходим для разработки
      type: Boolean,
      default: false,
    },
    datePickerOptions: {
      type: Object,
      default: () => ({
        firstDayOfWeek: 1,
      }),
    },
    text: {
      type: String,
      default: "",
    },
    activeText: {
      type: String,
      default: "",
    },
    inactiveText: {
      type: String,
      default: "",
    },
    uploadUrl: {
      type: String,
      default: "#",
    },
    rangeType: {
      type: String,
      default: "daterange",
    },
    calendarType: {
      type: String,
      default: "date",
    },
    dateFormat: {
      type: String,
      default: "dd.MM.yyyy",
    },
    elClass: {
      type: String,
      default: "el-select",
    },
    flex: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    editor: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timePickerOptions: {
        format: "HH:mm",
      },
      dateTimePickerOptions: {
        firstDayOfWeek: 1,
      },
      showAddBtn: false
    };
  },
  computed: {
    classObject() {
      return {
        "form-el_switch": this.type === "switch",
        "form-el_checkbox": this.type === "checkbox",
        "dev-outline": this.dev,
        "form-el_error": this.isError,
        "flex": this.flex,
        "custom-el": this.custom
      };
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("on-change", this.fieldName, value);
      },
    }
  },
  mounted() {
    if (this.inputMask) {
      const input = this.$refs.inputWrapper.$el.querySelector("input");
      this.inputMask.mask(input);
    }
  },
  methods: {
    querySearch(queryString, cb) {
      const options = this.options;
      // const results = queryString ? options.filter(this.createFilter(queryString)) : options;
      // this.showAddBtn = (results.length === 0) ? true : false;
      this.$store.dispatch("search/getSearchList", queryString).then((data) => {
        if (data.length === 0) {
          cb([]);
        } else {
          // debugger;
          cb(data);
        }
      })
    },
    createFilter(queryString) {
      return (option) => {
        return (option.value.toLowerCase().search(queryString.toLowerCase()) !== -1);
      };
    },
    addOption() {
      const newOption = {
        value: this.localValue,
        option: this.localValue
      }
      this.options.push(newOption)
      this.showAddBtn = false
      this.$notify({
        title: 'Новый атрибут',
        message: `"${newOption.value}" был успешно добавлен`,
        position: 'bottom-right',
        type: 'success',
        customClass: 'custom-notification'
      });
    }
  }
};
</script>

<style lang="scss">
.custom-autocomplete {
  position: relative;
  z-index: 1;

  .btn {
    position: absolute;
    z-index: 5000;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}
</style>
